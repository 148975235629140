import {
  CheckCircleIcon,
  ChevronLeftIcon,
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import type { InputType } from 'config'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getAccountExecutive, submitAccountExecutive } from 'services/apis'
import { Button } from 'stories/components'
import { InputConvert, InputValidate, RenderInput, useTitle } from 'utils'

import type { IAccountExecutive } from './types'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    firstName: {
      inputType: 'text0',
      title: 'First Name',
      required: true,
    },
    lastName: {
      inputType: 'text0',
      title: 'Last Name',
      required: true,
    },
    email: {
      inputType: 'text0',
      type: 'email',
      title: 'Email',
      required: true,
    },
    phone: {
      inputType: 'text0',
      type: 'phone',
      title: 'Phone',
      required: true,
    },
    company: {
      inputType: 'text0',
      title: 'Company',
      required: true,
    },
  }
}

export const AccountExecutivesPage = () => {
  useTitle(`Account Executive`, 'Get expert investment lending advice from Nextres account executives.')

  const urlParams: any = useParams()
  const { name } = urlParams
  const [isLoading, setLoading] = useState(true)
  const [isLoaded, setLoaded] = useState(false)
  const [isNotFound, setNotFound] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)
  const [data, setData] = useState<IAccountExecutive>()
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {
    if (!name) return
    setLoading(true)
    getAccountExecutive(name)
      .then(({ data }) => {
        if (!data) setNotFound(true)
        else setData(data)

        setLoaded(true)
      })
      .finally(() => setLoading(false))
  }, [name])

  const onChange = async (key: string, value: any) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onSubmit = () => {
    if (!data) return

    const newInputs = cloneDeep(inputs)
    let values: Record<string, any> = {}
    let hasError = false
    Object.keys(inputs).forEach((key) => {
      if (inputs[key].visible === false) return null
      newInputs[key].error = InputValidate(newInputs[key])
      const { value, error } = newInputs[key]
      if (error) hasError = true
      values[key] = value
    })
    setInputs(newInputs)
    if (hasError) return false

    setLoading(true)

    submitAccountExecutive(data.id, values)
      .then(() => setSubmitted(true))
      .finally(() => setLoading(false))
  }

  const render404 = () => {
    return (
      <div className="px-5 pb-12 max-w-screen-xl m-auto mt-20">
        <div className="text-4xl font-semibold">404 Not Found</div>
        <div className="mt-3">
          <p className="text-md leading-8 mb-5">Sorry, We can't find the proper Account Executive.</p>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (isLoading && !isLoaded) return <></>
    if (isNotFound || !data) return render404()

    return (
      <div className="px-5 pb-12 max-w-screen-xl m-auto mt-10">
        <Link to="/account_executives">
          <div className="flex gap-2 items-center hover:underline hover:text-indigo-500">
            <ChevronLeftIcon className="first-line:w-4 h-4 text-gray-600 transition-all duration-500 transform " /> Back
          </div>
        </Link>
        <div className="flex sm:items-center gap-8 mb-8 flex-col sm:flex-row mt-10">
          <img src={data.photoUrl} className="w-64 h-64 rounded-full object-cover border-4 border-gray-200" />
          <div>
            <div className="text-4xl font-semibold mb-2">
              {data.firstName} {data.lastName}
            </div>
            <p className="text-md leading-8 mb-4 italic">{data.title}</p>
            <a href={`mailto:${data.email}`} className="flex items-center gap-2 mb-2 text-gray-700">
              <EnvelopeIcon className="w-4 h-4" /> {data.email}
            </a>
            <a href={`tel:${data.phone}`} className="flex items-center gap-2 text-gray-700 mb-2">
              <DevicePhoneMobileIcon className="w-4 h-4" /> {data.phone}
            </a>
            {!!data.workPhone && (
              <a href={`tel:${data.workPhone}`} className="flex items-center gap-2 text-gray-700 mb-2">
                <PhoneIcon className="w-4 h-4" /> {data.workPhone}
              </a>
            )}

            <div className="flex items-center gap-4 mb-4">
              {!!data.linkedInUrl && (
                <a
                  className="flex items-center gap-2 text-indigo-500 hover:underline cursor-pointer"
                  href={data.linkedInUrl}
                >
                  <svg className="h-4 w-4 rounded-sm fill-indigo-500" viewBox="0 0 24 24">
                    <path d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z" />
                  </svg>{' '}
                  View on LinkedIn
                </a>
              )}
              {!!data.facebookUrl && (
                <a
                  className="flex items-center gap-2 text-indigo-500 hover:underline cursor-pointer"
                  href={data.facebookUrl}
                >
                  <svg className="h-4 w-4 rounded-sm fill-indigo-500" viewBox="0 0 24 24">
                    <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />
                  </svg>{' '}
                  View on Facebook
                </a>
              )}
            </div>
            <div className="flex items-center gap-4">
              <Button onClick={() => window.open(`https://commercial.nextres.com/register/${data.userID}`, '_blank')}>
                Become a Broker
              </Button>
            </div>
          </div>
        </div>

        <div className="flex gap-8 sm:flex-row flex-col">
          <div className="flex-1">
            <p className="capitalize mb-2 text-xl font-semibold">About {data.firstName}</p>
            {data.description.split('\n').map((v) => (
              <p className="text-gray-600 mb-2">{v}</p>
            ))}
          </div>

          <div className="sm:w-96 flex flex-col gap-0 sm:border-l sm:pl-8 sm:border-t-0 sm:pt-0 border-t pt-8 text-gray-700">
            {!isSubmitted ? (
              <>
                <p className="capitalize mb-2 text-xl font-semibold text-gray-900">Let's Talk</p>
                {Object.keys(inputs).map((key) => {
                  const input = inputs[key]

                  return (
                    <div className={input.span ? `col-span-${input.span}` : ''}>
                      <RenderInput input={input} Key={key} onChange={onChange} />
                    </div>
                  )
                })}
                <Button onClick={onSubmit} loading={isLoading}>
                  Submit
                </Button>
              </>
            ) : (
              <div className="border border-green-500 rounded-lg p-4 bg-green-50 text-green-600 text-center">
                <CheckCircleIcon className="w-12 h-12 text-green-500 mx-auto mb-2" />
                We will reach out back to you as soon as possible.
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full">
      <div className="content">
        <div className="AccountExecutive-container">
          <LayoutLoading show={isLoading && !isLoaded} />
          {renderContent()}
        </div>
      </div>
    </div>
  )
}
